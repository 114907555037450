import React from 'react'
import { styled } from '@mui/material/styles'
import { ClickAwayListener, IconButton, Typography, Popover, MenuItem } from '@mui/material'
import { Close, Menu, ExpandMore, ArrowForwardIosSharp } from '@mui/icons-material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import './navBar.css'
import HamburgerItem, { INavBarHamburgerItem } from './navBarHamburgerItem'
import { Link } from 'react-router-dom'
import Data from '../../menuData.json'
import { useSelector } from 'react-redux'
import { baseURLCatalogue, baseURLSubmenu } from '../../utils'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  () => ({
    borderBottom: 'none',
    '&:before': {
      display: 'none'
    }
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharp />} {...props} />
))(() => ({
  padding: '0 8px',
  minHeight: 36,
  color: '#000000DE',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: '4px 8px'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '8px 16px 16px',
  background: '#f8f8f8'
}))

export default function NavBarHamburger() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { isAdmin } = useSelector((state: any) => state.userAccount)

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <IconButton
        className='iconbtn-navbar'
        disableTouchRipple
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        {!open ? <Menu style={{ color: '#222' }} /> : <Close style={{ color: '#222' }} />}
      </IconButton>
      <Popover
        PaperProps={{ style: { padding: '.5rem 0' } }}
        open={open}
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography className='menu-hamburger'>Products</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Data.Catalogue.map((data: INavBarHamburgerItem, key: number) => (
                  <HamburgerItem title={data.title} url={`${baseURLCatalogue()}/${data.url}`} key={key} />
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel2a-content' id='panel2a-header'>
                <Typography className='menu-hamburger'>Services</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Data.Services.map((data: INavBarHamburgerItem, key: number) =>
                  data.title.includes('Solutions') ? (
                    <MenuItem
                      style={{ minHeight: 32, fontSize: 14, padding: '0 1rem' }}
                      disableTouchRipple
                      component={Link}
                      target='_blank'
                      to={data.url}
                    >
                      {data.title}
                    </MenuItem>
                  ) : (
                    <HamburgerItem
                      title={data.title}
                      url={baseURLSubmenu(data.url, data.title, 'services')}
                      key={key}
                    />
                  )
                )}
              </AccordionDetails>
            </Accordion>
            <Link to='/contact' className='link-hamburger' onClick={handleClose}>
              <Typography className='menu-hamburger'>Contact</Typography>
            </Link>
            {isAdmin && (
              <Link to='/admin' className='link-hamburger' onClick={handleClose}>
                <Typography className='menu-hamburger'>Admin</Typography>
              </Link>
            )}
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  )
}
