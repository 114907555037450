import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, MenuItem } from '@mui/material'
import { ExpandMore, ArrowForwardIosSharp } from '@mui/icons-material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import HamburgerItem, { INavBarHamburgerItem } from '../../components/Navbar/navBarHamburgerItem'
import Data from '../../menuData.json'
import { baseURLCatalogue, baseURLSubmenu } from '../../utils'
import { Link } from 'react-router-dom'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  () => ({
    background: 'transparent',
    borderBottom: '1px solid #d6d6d6',
    '&:before': {
      display: 'none'
    }
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharp />} {...props} />
))(() => ({
  padding: 0,
  minHeight: 40,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: '4px 0'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0 0 8px 0'
}))

export default function FooterMobile() {
  return (
    <div style={{ marginTop: '1rem' }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
          <Typography className='footer-title'>Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Data.Catalogue.map((data: INavBarHamburgerItem, key: number) => (
            <HamburgerItem title={data.title} url={`${baseURLCatalogue()}/${data.url}`} key={key} />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel2a-content' id='panel2a-header'>
          <Typography className='footer-title'>Services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Data.Services.map((data: INavBarHamburgerItem, key: number) =>
            data.title.includes('Solutions') ? (
              <MenuItem
                style={{ minHeight: 32, fontSize: 14, padding: '0 1rem' }}
                disableTouchRipple
                component={Link}
                target='_blank'
                to={data.url}
              >
                {data.title}
              </MenuItem>
            ) : (
              <HamburgerItem title={data.title} url={baseURLSubmenu(data.url, data.title, 'services')} key={key} />
            )
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel2a-content' id='panel2a-header'>
          <Typography className='footer-title'>About Us</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Data.AboutUs.map((data: INavBarHamburgerItem, key: number) => (
            <HamburgerItem title={data.title} url={data.url} key={key} />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
