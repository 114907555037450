import { Stack, Grid, List, ListItem, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Data from '../../menuData.json'
import { FOOTER_TARGET_SELF_ROUTES } from './constants'
import { baseURLCatalogue, baseURLSubmenu, linkTarget } from '../../utils'

export default function ContentFooter() {
  return (
    <Stack
      direction='column'
      alignItems='center'
      style={{ borderBottom: '1px solid #d6d6d6' }}
      p={{ xs: '10px 0px;', md: '0px 10px' }}
    >
      <Grid container maxWidth={600} columnSpacing={8}>
        <Grid item xs={4} md={4}>
          <List className='footer-link-list'>
            <Typography className='title' gutterBottom>
              Catalogue
            </Typography>
            {Data.Catalogue.map((data, key: number) => {
              return (
                <ListItem disablePadding disableGutters key={key}>
                  <Link target={linkTarget()} to={`${baseURLCatalogue()}/${data.url}`}>
                    {data.title}
                  </Link>
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={4} md={4}>
          <List className='footer-link-list'>
            <Typography className='title' gutterBottom>
              Services
            </Typography>
            {Data.Services.map((data, key: number) => (
              <ListItem disablePadding disableGutters key={key}>
                {data.title.includes('Solutions') ? (
                  <Link target='_blank' to={data.url}>
                    {data.title}
                  </Link>
                ) : (
                  <Link target={linkTarget()} to={baseURLSubmenu(data.url, data.title, 'services')}>
                    {data.title}
                  </Link>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={4} md={4}>
          <List className='footer-link-list'>
            <Typography className='title' gutterBottom>
              About Us
            </Typography>
            {Data.AboutUs.map((data, key: number) => (
              <ListItem disablePadding disableGutters key={key}>
                <Link
                  target={FOOTER_TARGET_SELF_ROUTES.some((route) => data.url.includes(route)) ? '_self' : '_blank'}
                  to={data.url}
                >
                  {data.title}
                </Link>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Stack>
  )
}
